
import * as Sentry from '@sentry/browser'

import { post as postPaymentCard } from '@/api/customer/order/card'
import { post as postAuthorization } from '@/api/customer/order/authorization'
import { defineComponent, ref, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useTranslation } from '@/locales'
import Agreements from '@/components/Service/Order/Card/QtDns/Agreement.vue'

export default defineComponent({
  components: {
    Agreements,
  },
  setup() {
    const route = useRoute()
    const { query } = route
    const { t } = useTranslation()
    const isAgree = ref(false)

    const onClick = () => {
      postPaymentCard(query.id as string)
    }

    return {
      onClick,
      t,
      isAgree,
    }
  },
})
