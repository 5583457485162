
import * as Sentry from '@sentry/browser'
import { post as postAuthorization } from '@/api/customer/order/authorization'
import { defineComponent, ref, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useTranslation } from '@/locales'
import Agreements from '@/components/Service/Order/Card/QtDomain/Agreement.vue'
import { startProgress, stopProgress } from '@/store/progress'

export default defineComponent({
  components: {
    Agreements,
  },
  setup() {
    const router = useRouter()
    const route = useRoute()
    const { locale } = route.params
    const { query } = route
    const { t } = useTranslation()

    const onClick = () => {
      startProgress()
      postAuthorization(query.id as string)
        .then(() => {
          router.push({
            path: `/${locale}/service/order/qt-domain/success`,
            query,
          })
          stopProgress()
        })
        .catch((err) => {
          console.log(54545454, err)
          stopProgress()
        })
    }

    return {
      onClick,
      t,
    }
  },
})
