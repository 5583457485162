import * as Sentry from '@sentry/browser'

import { errorHandler } from '@/api/utils'
import { http } from '@/plugins/ky'
import { stripeRedirect } from '@/api/utils'

export const post = (sessionId: string) =>
  new Promise<void>((resolve, reject) =>
    http
      .post('customer/order/card', {
        json: {
          sessionId,
        },
      })
      .then(async (r: any) => {
        const session = await r.json()
        await stripeRedirect(session.sessionId)
        resolve()
      })
      .catch(async ({ response, message }: any) => {
        await errorHandler(response, message)
        reject()
      })
  )
