
import * as Sentry from '@sentry/browser'
import { post as postAuthorization } from '@/api/customer/order/authorization'
import { defineComponent, ref, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useTranslation } from '@/locales'
import Agreements from '@/components/Agreements.vue'

export default defineComponent({
  components: {
    Agreements,
  },
  emits: ['clicked'],
  setup() {
    const router = useRouter()
    const route = useRoute()
    const { locale } = route.params
    const { query } = route
    const { t } = useTranslation()
    const isAgree = ref(false)

    // TODO: Factory でagreementList作った方がよい
    const agreementList =
      route.query.service != null
        ? [
            {
              url: `${process.env.VUE_APP_ORDER_APP}/${locale}/agreements/qualitia-dns/`,
              name: t('QUALITIA DNSサービス利用約款'), // まだ英語版がないので日本語のみ
            },
            {
              url: `${process.env.VUE_APP_ORDER_APP}/${locale}/agreements/anti-social/`,
              name: t('反社会的勢力排除に関する包括的覚書'), // まだ英語版がないので日本語のみ
            },
            {
              url: `https://www.qualitia.co.jp/privacy.html`, // まだ英語版がないので日本語のみ
              name: t('Privacy Policy'),
            },
          ]
        : []

    const onCheck = (agree: boolean) => (isAgree.value = agree)

    return {
      t,
      agreementList,
      onCheck,
      isAgree,
    }
  },
})
