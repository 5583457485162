import { useStore } from '@/store'
import { MutationType } from '@/store/mutations'

export function startProgress() {
  const { commit } = useStore()

  commit(MutationType.InProgress, true)
}

export function stopProgress() {
  const { commit } = useStore()

  commit(MutationType.InProgress, false)
}
