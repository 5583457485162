<template>
  <div class="container mx-auto max-w-md">
    <div class="md:mt-20 sm:mt-0 text-left">
      <div class="md:gap-6">
        <div class="md:col-span-1">
          <div class="md:mb-10 px-4 sm:px-0">
            <h3
              class="text-xl md:text-3xl leading-6 text-gray-800 font-bold mb-10"
            >
              {{ t('Apply domain transfer') }}
            </h3>
            <p class="text-base text-gray-500 mb-2">
              {{ t('If you click apply button, start to process') }}
            </p>
            <p class="text-base text-gray-500 mb-2">
              {{
                t(
                  'We will send you an email to tell what you need to do for domain transfer'
                )
              }}
            </p>
            <p class="text-base text-gray-500 mb-2">
              {{
                t(
                  'The payment will be fixed after domain transfer is completed'
                )
              }}
            </p>
          </div>
        </div>

        <Agreements @clicked="onClick">
          <template #btnName>{{ t('Apply') }}</template>
        </Agreements>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import * as Sentry from '@sentry/browser'
import { post as postAuthorization } from '@/api/customer/order/authorization'
import { defineComponent, ref, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useTranslation } from '@/locales'
import Agreements from '@/components/Service/Order/Card/QtDomain/Agreement.vue'
import { startProgress, stopProgress } from '@/store/progress'

export default defineComponent({
  components: {
    Agreements,
  },
  setup() {
    const router = useRouter()
    const route = useRoute()
    const { locale } = route.params
    const { query } = route
    const { t } = useTranslation()

    const onClick = () => {
      startProgress()
      postAuthorization(query.id as string)
        .then(() => {
          router.push({
            path: `/${locale}/service/order/qt-domain/success`,
            query,
          })
          stopProgress()
        })
        .catch((err) => {
          console.log(54545454, err)
          stopProgress()
        })
    }

    return {
      onClick,
      t,
    }
  },
})
</script>

<style scoped lang="postcss">
.disableBtn {
  @apply bg-gray-300 text-white;
}
</style>
