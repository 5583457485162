
import * as Sentry from '@sentry/browser'
import { defineComponent, ref, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useTranslation } from '@/locales'
import QtDomain from '@/components/Service/Order/Card/QtDomain/index.vue'
import QtDns from '@/components/Service/Order/Card/QtDns/index.vue'

export default defineComponent({
  components: {
    QtDomain,
    QtDns,
  },
  setup() {
    const route = useRoute()
    const { query } = route
    const { t } = useTranslation()
    const serviceName = ref(route.query.service)

    return {
      t,
      serviceName,
    }
  },
})
