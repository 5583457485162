<template>
  <div class="container mx-auto max-w-md">
    <div class="md:mt-20 sm:mt-0 text-left">
      <div class="md:gap-6">
        <div class="md:col-span-1">
          <div class="md:mb-10 px-4 sm:px-0">
            <h3
              class="text-xl md:text-3xl leading-6 text-gray-800 font-bold mb-10"
            >
              {{ t('Payment') }}
            </h3>

            <p class="text-base text-gray-500 mb-2">
              {{
                t('If you click purchase button, jumping to Stripe website.')
              }}
            </p>
            <p class="text-base text-gray-500">
              {{
                t('You can register or change your credit card if you need.')
              }}
            </p>
          </div>
        </div>

        <Agreements @clicked="onClick">
          <template #btnName>{{ t('Buy') }}</template>
        </Agreements>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import * as Sentry from '@sentry/browser'

import { post as postPaymentCard } from '@/api/customer/order/card'
import { post as postAuthorization } from '@/api/customer/order/authorization'
import { defineComponent, ref, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useTranslation } from '@/locales'
import Agreements from '@/components/Service/Order/Card/QtDns/Agreement.vue'

export default defineComponent({
  components: {
    Agreements,
  },
  setup() {
    const route = useRoute()
    const { query } = route
    const { t } = useTranslation()
    const isAgree = ref(false)

    const onClick = () => {
      postPaymentCard(query.id as string)
    }

    return {
      onClick,
      t,
      isAgree,
    }
  },
})
</script>

<style scoped lang="postcss">
.disableBtn {
  @apply bg-gray-300 text-white;
}
</style>
