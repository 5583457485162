<template>
  <div class="container mx-auto max-w-md">
    <div class="md:mt-20 sm:mt-0 text-left">
      <div class="md:gap-6">
        <Agreements
          v-if="agreementList.length > 0"
          :on-agree="onCheck"
          :agreements="agreementList"
        />
        <div class="text-right mt-2">
          <button
            :disabled="isAgree === false && agreementList.length > 0"
            class="cursor-pointer inline-flex justify-center py-2 px-8 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-500"
            :class="{
              disableBtn: isAgree === false && agreementList.length > 0,
            }"
            @click="$emit('clicked')"
          >
            <slot name="btnName">{{ t('Buy') }}</slot>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import * as Sentry from '@sentry/browser'
import { post as postAuthorization } from '@/api/customer/order/authorization'
import { defineComponent, ref, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useTranslation } from '@/locales'
import Agreements from '@/components/Agreements.vue'

export default defineComponent({
  components: {
    Agreements,
  },
  emits: ['clicked'],
  setup() {
    const router = useRouter()
    const route = useRoute()
    const { locale } = route.params
    const { query } = route
    const { t } = useTranslation()
    const isAgree = ref(false)

    // TODO: Factory でagreementList作った方がよい
    const agreementList =
      route.query.service != null
        ? [
            {
              url: `${process.env.VUE_APP_ORDER_APP}/${locale}/agreements/qualitia-domain-general-jp/`,
              name: t('QUALITIA DOMAIN 汎用JPサービス利用約款'), // まだ英語版がないので日本語のみ
            },
            {
              url: `${process.env.VUE_APP_ORDER_APP}/${locale}/agreements/anti-social/`,
              name: t('反社会的勢力排除に関する包括的覚書'), // まだ英語版がないので日本語のみ
            },
            {
              url: `https://www.qualitia.co.jp/privacy.html`, // まだ英語版がないので日本語のみ
              name: t('Privacy Policy'),
            },
          ]
        : []

    const onCheck = (agree: boolean) => (isAgree.value = agree)

    return {
      t,
      agreementList,
      onCheck,
      isAgree,
    }
  },
})
</script>

<style scoped lang="postcss">
.disableBtn {
  @apply bg-gray-300 text-white;
}
</style>
