
import * as Sentry from '@sentry/browser'
import { defineComponent, ref, reactive } from 'vue'
import { useTranslation } from '@/locales'

type Agreement = {
  url: string
  name: string
}

export default defineComponent({
  props: {
    agreements: {
      type: Array,
      required: true,
    },
    onAgree: {
      type: Function,
      required: true,
    },
  },
  setup(props) {
    const items = reactive<{ agreements: Agreement[] }>({
      agreements: props.agreements as Agreement[],
    })
    const agrees = ref(items.agreements)
    const { t } = useTranslation()
    const isChecked = ref(false)

    const onClicked = () => props.onAgree(isChecked.value)

    return {
      t,
      onClicked,
      agrees,
      isChecked,
    }
  },
})
