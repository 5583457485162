<template>
  <div class="pb-4 col-span-2 bg-gray-100 p-4">
    <div class="flex justify-start mb-2">
      <label for="phone" class="block text-sm font-medium text-gray-700"
        >{{ t('Agreements') }}
      </label>
      <div class="required">{{ t('Required') }}</div>
    </div>
    <div>
      <ul>
        <li v-for="(agreement, index) in agrees" :key="index">
          <a
            :href="agreement.url"
            class="text-blue-500 mb-2 text-xs"
            target="_blank"
          >
            {{ agreement.name }}
          </a>
        </li>
      </ul>
      <p class="text-xs mt-2">
        {{ t('Do you agree the above agreements?') }}
      </p>

      <label class="flex justify-end items-center">
        <div
          class="bg-white border-2 rounded border-gray-400 w-4 h-4 flex flex-shrink-0 justify-center items-center mr-2 focus-within:border-blue-500"
        >
          <input
            type="checkbox"
            v-model="isChecked"
            class="opacity-0 absolute"
            @change="onClicked"
          />

          <svg
            class="fill-current hidden w-4 h-4 text-green-500 pointer-events-none"
            viewBox="0 0 20 20"
          >
            <path d="M0 11l2-2 5 5L18 3l2 2L7 18z" />
          </svg>
        </div>

        <div class="select-none text-sm">{{ t('I agree') }}</div>
      </label>
    </div>
  </div>
</template>

<script lang="ts">
import * as Sentry from '@sentry/browser'
import { defineComponent, ref, reactive } from 'vue'
import { useTranslation } from '@/locales'

type Agreement = {
  url: string
  name: string
}

export default defineComponent({
  props: {
    agreements: {
      type: Array,
      required: true,
    },
    onAgree: {
      type: Function,
      required: true,
    },
  },
  setup(props) {
    const items = reactive<{ agreements: Agreement[] }>({
      agreements: props.agreements as Agreement[],
    })
    const agrees = ref(items.agreements)
    const { t } = useTranslation()
    const isChecked = ref(false)

    const onClicked = () => props.onAgree(isChecked.value)

    return {
      t,
      onClicked,
      agrees,
      isChecked,
    }
  },
})
</script>

<style scoped lang="postcss">
.grid div {
  @apply mx-2;
}

input,
select {
  @apply w-full mt-1 py-2 px-3 block border border-gray-300 shadow-sm rounded-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm;
}

.disableBtn {
  @apply bg-gray-300 text-white;
}

.required {
  @apply ml-2 bg-red-500 font-medium text-gray-50 text-center inline-block px-2 text-sm;
}

input:checked + svg {
  display: block;
}
</style>
